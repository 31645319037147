@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

body {
  margin: 0;
  font-family:
    'Manrope',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  color: #23222d;
  background-color: #f7f6f3;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: gsrayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.quill .ql-editor {
  font-family: 'Manrope', sans-serif !important;
  padding: 0;
}

.quill .ql-editor p {
  font-size: 16px;
  color: #23222d;
}

.quill .ql-editor h1 {
  font-size: 36px;
  color: #23222d;
}

.quill .ql-editor h2 {
  font-size: 32px;
  color: #23222d;
}

.quill .ql-editor h3 {
  font-size: 28px;
  color: #23222d;
}

.quill .ql-editor h4 {
  font-size: 24px;
  color: #23222d;
}

.quill .ql-editor h5 {
  font-size: 20px;
  color: #23222d;
}

.quill .ql-editor h6 {
  font-size: 18px;
  color: #23222d;
}
